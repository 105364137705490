button.link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}
button.link:disabled {
    color: #A6A6A6;
}

button.install {
    background-color: #0078D4;
    color: #FFFFFF;
    text-align: center;
    width: 150px;
    height: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-bottom: 25px;
    border-radius: 5px;
}

button.install:disabled {
    background-color: #A6A6A6;
}